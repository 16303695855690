import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {
    Button,
    Container,
    TextInput,
    DatePicker,
    H5,
    ConfirmationBox,
    IconButton
} from '../../components/index.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { convertDateToDBFormat } from '../../util/index.js'
import { selectEmail, selectUserDetails } from '../../reducers/authReducer.js';
import { getEnableLitmosSubmit } from '../../reducers/dashboardReducer.js'
import moment from 'moment';
import Modal from 'react-modal';
import Label from '../../components/ncss/Label.js';
import Popover, { ArrowContainer } from 'react-tiny-popover';

const validateCertificateId = value => value && !/^[0-9]{9}$/.test(value) &&
    'Invalid input, expect 9 digits.'

export class Litmos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitModal: false,
            TrainingFiles: [],
            filePreviews: [],
            modalIsOpen: false,
            selectedFile: null,
            onlyAttachment:true,
            uploadedFiles: [],
            isPopoverOpen: false
        };

    }
    handleFileChange = (e) => {
        let files = Array.from(e.target.files);
        this.setState({ TrainingFiles: files });
        files.forEach(file => {
            let reader = new FileReader();

            reader.onloadend = () => {
                this.setState(prevState => ({
                    filePreviews: [...prevState.filePreviews, { fileName: file.name, base64: reader.result }]
                }));
            };

            reader.readAsDataURL(file);
        });
        // Reset the value of the file input
        e.target.value = null;
    }

    submitLitmosData = (resubmit) => {
        const { litmosInfo, submitLitmos, email, shouldEnableLitmosSubmit, userDetails } = this.props;
        const { filePreviews,onlyAttachment,uploadedFiles } = this.state;
        shouldEnableLitmosSubmit(true);
        const reqObj = {};
        if(!onlyAttachment){
        reqObj.certificate = litmosInfo.certificateId;
        reqObj.completion = convertDateToDBFormat(litmosInfo.completion) || '';
        const expirationDate = litmosInfo.completion; // expiration date = completion + 2years
        expirationDate.setFullYear(new Date(moment(litmosInfo.completion).utc().format('DD-MMM-YYYY')).getFullYear() + 2);
        reqObj.expiration = convertDateToDBFormat(expirationDate);
        if(userDetails.litmos !== null){ 
            reqObj.renew = resubmit;
            reqObj.uploadId =userDetails.litmos.id
        } 
        reqObj.attachment = [...filePreviews, ...uploadedFiles];
    }
        if(onlyAttachment){
        reqObj.onlyAttachment = true; 
        reqObj.attachment = [...filePreviews, ...uploadedFiles];
        reqObj.uploadId =userDetails.litmos.id;  
        }
        reqObj.email = email;
        submitLitmos(reqObj);
        this.setState({onlyAttachment: true});

    }
    closeSubmitModal = () => {
        this.setState({ submitModal: false });
    };
    openConfirmationPopup = () => {
        this.setState({ submitModal: true });
    }
    displayPopOver = () => {
        const { isPopoverOpen } = this.state;
        const togglePopover = !isPopoverOpen;
        this.setState({ isPopoverOpen: togglePopover });
    };
    shouldEnableReSubmit = () => {
        const { userDetails, litmosSubmit,invalid } = this.props;
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = new Date(moment(userDetails.litmos.expiration).utc().format('DD-MMM-YYYY'));
        const utc2 = new Date(moment(new Date()).utc().format('DD-MMM-YYYY'));
        return ((Math.floor((utc1 - utc2) / _MS_PER_DAY) <= 45) || userDetails.LitmosStatus !== "completed" || Math.floor(utc1 - utc2) === 1) && !litmosSubmit && !invalid ? true : false

    }
    shouldEnableSubmit = () => {
        const { litmosInfo,litmosSubmit, invalid } = this.props;
        if (litmosInfo.certificateId !== '' && litmosInfo.completion !== "" && !litmosSubmit && !invalid ) {
            return true
        }
        return false
    }
    openModal = (file) => {
        this.setState({ modalIsOpen: true, selectedFile: file });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false, selectedFile: null });
    }
    handleDeleteExistingFile = (fileToDelete) => {
        this.props.clearAttachment()
        const { uploadedFiles } = this.state;
        const index = uploadedFiles.findIndex(file => file.fileName === fileToDelete);
        if (index > -1) {
            let files = [...uploadedFiles];
            files.splice(index, 1);
            this.setState({ uploadedFiles: files });
        }
    }
    handleDelete = (index) => {
        let files = [...this.state.filePreviews];
        files.splice(index, 1);
        this.setState({ filePreviews: files });
    }
    componentDidUpdate(prevProps) {
        // Check if certificateId has change      
        if (this.props.litmosInfo.certificateId !== prevProps.initialValues.certificateId || 
            this.props.litmosInfo.completion !== prevProps.initialValues.completion) {
          if (this.state.onlyAttachment) {
            this.setState({onlyAttachment: false});
        }
        }
    }
    componentDidMount() {
        const { previewAttachment, userDetails } = this.props;
        userDetails.fileNames && userDetails.fileNames.forEach(fileName => {
            fileName !== null && previewAttachment({ id: userDetails.litmos.id, formType: 'trainingCertificate',userId: userDetails.userId,fileName: fileName });
        });
    }
   
    render() {
        const { userDetails, initialValues, previewAttachmentData } = this.props;
        const { submitModal, uploadedFiles, filePreviews, isPopoverOpen } = this.state
        if (userDetails.certificateId !== "") {
            initialValues.certificateId = userDetails.certificateId
            initialValues.completion = new Date(userDetails.completion)
        }

        if(previewAttachmentData.data && !this.state.uploadedFiles.some(file => previewAttachmentData.data.fileName === file.fileName)){
            let content = previewAttachmentData.data.Content;
                content = content.replace(/data/g, 'data:');
                content = content.replace(/pdf/g, 'pdf;');
                content = content.replace(/base64/g, 'base64,');
                    this.setState({
                        uploadedFiles: [...this.state.uploadedFiles, { fileName: previewAttachmentData.data.fileName, base64: content }]
                    });
        }    
        return (
            <form >
                <Container extraClassName="ncss-col-sm-12  display-flex" section>
                    <div className="ncss-col-sm-8">
                        <div className=" ncss-input-container ">
                            <H5 className="ncss-col-sm-4 u-bold description p2-top-sm p2-bottom-sm">Certificate ID#<span className="astericks">&#42;</span></H5>
                            <div className="ncss-col-sm-6"><Field component={TextInput} name="certificateId" type="text" validate={validateCertificateId} /></div>
                        </div>
                        <div><H5 className="ncss-col-sm-4 u-bold description p2-top-sm p2-bottom-sm mt2-sm">Completion Date<span className="astericks">&#42;</span></H5>
                            <div className="ncss-col-sm-6">
                                <Field
                                    component={DatePicker}
                                    defaultValue={initialValues && initialValues.completion !== '' ? initialValues.completion : null}
                                    showTime={false}
                                    name="completion"
                                />
                            </div>
                        </div>
                        <div>
                            <H5 className="ncss-col-sm-4 u-bold description p2-top-sm p2-bottom-sm">Upload Certificate(s)
                                <Popover
                                    isOpen={isPopoverOpen}
                                    position="bottom"
                                    padding={5}
                                    onClickOutside={this.displayPopOver}
                                    content={({ position, targetRect, popoverRect }) => (
                                        <ArrowContainer
                                            position={position}
                                            targetRect={targetRect}
                                            popoverRect={popoverRect}
                                            arrowColor="#dddcde"
                                            arrowSize={0}
                                            arrowStyle={{ opacity: 0.7, left: '120px' }}
                                        >
                                            <IconButton icon="times" className="popover-closeButton" onClick={this.displayPopOver} />
                                            <div className="popover-trfsection">

                                                <ol className="ncss-list-ol ol-font-size">
                                                    <li className="ncss-li">
                                                        Upload all the relevant certificates.
                                                    </li>
                                                </ol>
                                            </div>
                                        </ArrowContainer>
                                    )}
                                >
                                    <span className='info-box'>
                                        <IconButton icon="comment-alt" extraClassName="color-trf" onClick={this.displayPopOver} />
                                    </span>
                                </Popover>
                            </H5>

                            <div className="ncss-col-sm-6 mt-10">
                            <input type="file" id="fileInput" style={{display: "none"}} onChange={this.handleFileChange} accept='.pdf' />
                            <Label htmlFor="fileInput" className="custom-file-upload pl4-sm pr4-sm u-bold description">
                                Browse...
                            </Label> 
                            <H5 className="description" >
                                Accepted file types: .pdf
                            </H5>
                            </div>
                        </div>
                        <div className="ncss-col-sm-4"></div>
                        <div className="ncss-col-sm-8">
                            {filePreviews.map((filePreview, index) => (
                                <div key={index}>
                                    <div className="ncss-col-sm-9"><p>{filePreview.fileName}</p></div>
                                    <div className="ncss-col-sm-1">
                                        <i className="fas fa-eye" alt="Preview" onClick={() => this.openModal(filePreview)} />
                                    </div>
                                    <div className="ncss-col-sm-1"><a href={filePreview.base64} download={filePreview.fileName}>
                                        <i className='fa fa-download download-fontSize' alt="Download" />
                                   </a></div>
                                   <div className="ncss-col-sm-1">
                                        <i className="fas fa-trash" onClick={() => this.handleDelete(index)}/>
                                    </div>
                                </div>
                            ))}
                            {userDetails.fileNames !== null && uploadedFiles.map((filePreview, index) => (
                                filePreview !== null && <div key={index}>
                                    <div className="ncss-col-sm-9"><p>{filePreview.fileName}</p></div>
                                    <div className="ncss-col-sm-1">
                                        <i className="fas fa-eye" alt="Preview" onClick={() => this.openModal(filePreview)} />
                                    </div>
                                    <div className="ncss-col-sm-1"><a href={filePreview.base64} download={filePreview.fileName}>
                                        <i className='fa fa-download download-fontSize' alt="Download" />
                                   </a></div>
                                <div className="ncss-col-sm-1">
                                    <i className="fas fa-trash" onClick={() => this.handleDeleteExistingFile(filePreview.fileName)}/>
                                </div>
                                </div>
                            ))}
                        </div>
                        <Modal
                            isOpen={this.state.modalIsOpen}
                            onRequestClose={this.closeModal}
                            contentLabel="File Preview"
                            overlayClassName="modal-overlay"
                        >
                            <IconButton icon="times" className="modal-close-btn" onClick={this.closeModal} />

                            <object data={this.state.selectedFile ? this.state.selectedFile.base64 : ''} type="application/pdf" width="100%" height="100%">
                            </object>
                            <button onClick={this.closeModal}>Close</button>
                        </Modal>
                        <div className="ncss-col-sm-12">
                        {userDetails.litmos === '' || userDetails.litmos === null ?
                            <><div className="ncss-col-sm-6"></div>
                            <Button
                                className={`ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected mt2-sm ${this.shouldEnableSubmit() ? '' : 'disabled'}`}
                                onClick={() => { this.submitLitmosData() }}
                            >SUBMIT
                            </Button></>
                            :<><div className="ncss-col-sm-4"></div>
                            <Button
                                className={`ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected mt2-sm`}
                                onClick={() => { this.openConfirmationPopup() }}
                            >RE-SUBMIT
                            </Button><h6 className='commentStyle ncss-col-sm-4 pt2-sm'><span className="astericks">&#42;</span><span className="astericks">&#42;</span>
                            Allow 7-10 working days for processing. Do not re-submit unless you are instructed to do so by RSL Support</h6></> }
                            </div>   
                    </div>
                    <div className="ncss-col-sm-4 commentStyle" >
                        <h6 className='pb2-sm'>For questions on RSL training contact, <strong>Chemistry.Training@nike.com</strong></h6>
                        <h6 className='pb2-sm' ><strong>Note:</strong> When an email address is requested for Training make sure to use the same email address you used for the RSL application login. </h6>
                    </div>
                </Container>
                <div className="ncss-col-sm-12">
                    <h6 className="u-bold support-link">For any other questions,please reach out to <span className='text-color-accent'>RSLSupport@nike.com</span></h6>
                </div>
                <ConfirmationBox
                    message={
                        'Do you want to Re-submit Litmos ?'
                    }
                    submitModal={submitModal}
                    submitValue={
                        () => {
                            this.submitLitmosData(true);
                        }
                    }
                    closeModal={this.closeSubmitModal}
                />
            </form>

        )
    }
}

Litmos.propTypes = {

};

const mapStateToProps = state =>({
    litmosInfo: getFormValues('Litmos')(state),
    email: selectEmail(state),
    userDetails: selectUserDetails(state),
    litmosSubmit: getEnableLitmosSubmit(state),
    previewAttachmentData: state.previewAttachment.previewAttachmentData
})
   

const mapDispatchToProps = dispatch => ({
    submitLitmos: data => {
        dispatch(createServiceAction('addLitmos', 'requested')(data));
    },
    shouldEnableLitmosSubmit: (data) => {
        dispatch(createServiceAction('enableLitmos', 'requested')(data));
    },
    previewAttachment: data => {
        dispatch(createServiceAction('previewAttachment', 'requested')(data));
    },
    clearAttachment: data => {
        dispatch(createServiceAction('clearAttachment', 'requested')(data));
    }
});
export default compose(
    reduxForm({
        form: 'Litmos',
        initialValues: {
            completion: '',
            certificateId: "",
            id: "",
            fileNames: []
        },
        enableReinitialize: true,
         destroyOnUnmount: false
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Litmos);
